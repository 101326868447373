


.wiki-logo {
    max-width: 110px;
}

.gp-footer {
    margin-top: 25px;
}


html, body {
    height: 100%;
    margin: 0;
  }
  
  .footer {
    background-color: #f8f8f8; /* Your footer background color */
    color: black; /* Your footer text color */
    text-align: center;
    margin-top: auto;
  }
  
  /* Adjust the container that wraps your entire app */
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  

  .documentation-box .category {
    color: #a46af9;
  }


  .rich-text img {
    max-width: 100%;
    height: auto;
  }